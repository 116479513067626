import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import { ToolbarContainer } from '../../Container';

import { useURLQuery } from '../../Common/Hook/urlQuery';
import useWindowSize from '../../Common/Hook/useWindowSize';

import {
  AGENCIES_ENDPOINT,
  AppContext,
  APPLICANT_DETAILS_ENDPOINT,
  APPLICATION_OVERVIEW_ENDPOINT,
  ApplicationContext,
  APPLICATIONS_BETA_ENDPOINT,
  APPLICATIONS_DETAILS_ENDPOINT,
  APPLICATIONS_ENDPOINT,
  AuthContext,
  CALENDAR_ENDPOINT,
  DASHBOARD_ENDPOINT,
  HR_REPORTS_ENDPOINT,
  INTERVIEWS_ENDPOINT,
  LEVELS_ENDPOINT,
  MOBILE_VIEW_WIDTH,
  MY_OPEN_POSITIONS_ENDPOINT,
  OtherContext,
  PANEL_REPORTS_ENDPOINT,
  POSITIONS_ENDPOINT,
  PRE_SCREENING,
  REFERRALS_ENDPOINT,
  RESUME_SCREENING,
  ROLES,
  SETTINGS_ENDPOINT,
  SKILLS_ENDPOINT,
  STREAMS_ENDPOINT,
  TABULAR_EVENTS_ENDPOINT,
  TEAM_REQUIREMENTS_END_POINT,
  TEAMS_ENDPOINT,
  TITLES,
  USERS_ENDPOINT,
} from '../../constants';

import AddApplicationUIWrapper from '../Applicants/AddApplication';
import LogoutButton from '../LogInAndLogout/LogoutButton';
import Notifications from '../Notifications';
import { FullScreenOverlay } from '../Overlay';
import Search from '../SearchBox';

import './toolBar.css';

const headingName = {
  [DASHBOARD_ENDPOINT]: TITLES.DASHBOARD,
  [POSITIONS_ENDPOINT]: TITLES.POSITIONS,
  [APPLICATIONS_ENDPOINT]: TITLES.APPLICATIONS,
  [APPLICATIONS_BETA_ENDPOINT]: TITLES.APPLICATIONS_BETA,
  [APPLICATION_OVERVIEW_ENDPOINT]: TITLES.APPLICATION_OVERVIEW,
  [REFERRALS_ENDPOINT]: TITLES.REFERRALS,
  [CALENDAR_ENDPOINT]: TITLES.CALENDAR,
  [RESUME_SCREENING]: TITLES.RESUME_SCREENING,
  [PRE_SCREENING]: TITLES.PRE_SCREENING,
  [TEAMS_ENDPOINT]: TITLES.TEAMS,
  [TEAM_REQUIREMENTS_END_POINT]: TITLES.REQUIREMENT,
  [USERS_ENDPOINT]: TITLES.USERS,
  [LEVELS_ENDPOINT]: TITLES.LEVELS,
  [SKILLS_ENDPOINT]: TITLES.SKILLS,
  [STREAMS_ENDPOINT]: TITLES.STREAMS,
  [AGENCIES_ENDPOINT]: TITLES.AGENCIES,
  [HR_REPORTS_ENDPOINT]: TITLES.HR_REPORTS,
  [INTERVIEWS_ENDPOINT]: TITLES.INTERVIEWS,
  [MY_OPEN_POSITIONS_ENDPOINT]: TITLES.MY_OPEN_POSITIONS,
  [SETTINGS_ENDPOINT]: TITLES.SETTINGS,
  [PANEL_REPORTS_ENDPOINT]: TITLES.PANEL_REPORTS,
  [APPLICANT_DETAILS_ENDPOINT]: TITLES.APPLICANT_DETAILS,
  [TABULAR_EVENTS_ENDPOINT]: TITLES.TABULAR_CALENDAR,
};

const respectiveRoles = {
  [DASHBOARD_ENDPOINT]: [ROLES.ADMIN, ROLES.AGENCY, ROLES.HIRING_MANAGER, ROLES.HR, ROLES.USER],
  [POSITIONS_ENDPOINT]: [ROLES.ADMIN, ROLES.AGENCY, ROLES.HIRING_MANAGER, ROLES.HR, ROLES.USER],
  [APPLICATIONS_ENDPOINT]: [ROLES.ADMIN, ROLES.HIRING_MANAGER, ROLES.HR, ROLES.USER],
  [APPLICATIONS_BETA_ENDPOINT]: [ROLES.ADMIN, ROLES.HIRING_MANAGER, ROLES.HR, ROLES.USER],
  [APPLICATION_OVERVIEW_ENDPOINT]: [ROLES.ADMIN, ROLES.HIRING_MANAGER, ROLES.HR],
  [REFERRALS_ENDPOINT]: [ROLES.ADMIN, ROLES.AGENCY, ROLES.HIRING_MANAGER, ROLES.HR, ROLES.USER],
  [CALENDAR_ENDPOINT]: [ROLES.ADMIN, ROLES.HIRING_MANAGER, ROLES.HR, ROLES.USER],
  [RESUME_SCREENING]: [ROLES.ADMIN, ROLES.HIRING_MANAGER],
  [PRE_SCREENING]: [ROLES.ADMIN, ROLES.HR],
  [TEAMS_ENDPOINT]: [ROLES.ADMIN, ROLES.HIRING_MANAGER, ROLES.HR],
  [TEAM_REQUIREMENTS_END_POINT]: [ROLES.ADMIN, ROLES.HIRING_MANAGER, ROLES.HR],
  [USERS_ENDPOINT]: [ROLES.ADMIN],
  [LEVELS_ENDPOINT]: [ROLES.ADMIN],
  [SKILLS_ENDPOINT]: [ROLES.ADMIN],
  [STREAMS_ENDPOINT]: [ROLES.ADMIN],
  [AGENCIES_ENDPOINT]: [ROLES.ADMIN],
  [HR_REPORTS_ENDPOINT]: [ROLES.ADMIN],
  [INTERVIEWS_ENDPOINT]: [ROLES.ADMIN, ROLES.AGENCY, ROLES.HIRING_MANAGER, ROLES.HR, ROLES.USER],
  [MY_OPEN_POSITIONS_ENDPOINT]: [ROLES.ADMIN, ROLES.HIRING_MANAGER, ROLES.HR],
  [SETTINGS_ENDPOINT]: [ROLES.ADMIN],
  [PANEL_REPORTS_ENDPOINT]: [ROLES.ADMIN, ROLES.HR],
  [APPLICANT_DETAILS_ENDPOINT]: [ROLES.ADMIN, ROLES.HR],
  [TABULAR_EVENTS_ENDPOINT]: [ROLES.ADMIN, ROLES.HIRING_MANAGER, ROLES.HR, ROLES.USER],
};
/**
 * function is ToolBar component which will return toolbar for all the pages
 * @function ToolBarUI
 */
export function ToolBarUI({ teamData, userData }) {
  const location = useLocation().pathname;
  const urlQuery = useURLQuery();
  const navigate = useNavigate();
  const { userName, teamName } = useLocation().state ?? {};
  const positionID = urlQuery.get('positionID');
  const userID = urlQuery.get('userID');
  const { isFullScreen } = useContext(OtherContext);
  const { search, setSearch } = useContext(ApplicationContext);
  const { userInfo } = useContext(AuthContext);
  const { appData } = useContext(AppContext);
  const role = userInfo?.roles && userInfo?.roles[0];
  const totalPositionsData = appData.POSITION_DATA || [];
  const [tableView, setTableView] = useState(false);
  const positionData = totalPositionsData.filter(
    (position) => position.id === Number(positionID)
  )[0];
  const initialSearch = urlQuery.get('searchParam') || search.searchValue;

  useEffect(() => {
    if (search.searchValue !== '') {
      navigate({ pathname: APPLICATIONS_ENDPOINT, search: `searchParam=${search.searchValue}` });
    }
  }, [search.searchValue]);

  const handleOnFilterChange = (updatedFilters) => {
    setSearch((prev) => ({ ...prev, ...updatedFilters }));
  };

  useEffect(() => {
    if (location !== APPLICATIONS_ENDPOINT) {
      setSearch((prevFilters) => ({ ...prevFilters, searchValue: '' }));
    } else setSearch((prevFilters) => ({ ...prevFilters, searchValue: initialSearch || '' }));
  }, [location]);

  const finalPanelName =
    location === INTERVIEWS_ENDPOINT &&
    (!userID || Number(userID) === userInfo?.entityID
      ? headingName[location]
      : (userName || userData?.data?.name) && `${userName || userData?.data?.name}'s Interviews`);

  const finalTeamName =
    location === TEAM_REQUIREMENTS_END_POINT && (teamName || teamData?.data?.name || '');

  const finalPositionName =
    location === APPLICATIONS_DETAILS_ENDPOINT && (positionData?.title || '');

  const finalAllowedOperation = positionData?.allowedOperations?.includes('ADD APPLICATION');

  const isMobileScreen = useWindowSize().width <= MOBILE_VIEW_WIDTH;

  useEffect(() => {
    if (location === TABULAR_EVENTS_ENDPOINT || location === CALENDAR_ENDPOINT) {
      if (tableView === true) navigate('/calendar/table');
      if (tableView === false) navigate('/calendar');
    }
  }, [tableView]);
  const handleSwitch = () => {
    setTableView(!tableView);
  };
  return (
    <div className={`main-container-toolbar ${isFullScreen ? 'sidebar-open' : ''}`}>
      <div className="head-text__heading">
        <h1 className="head-text">
          {finalPanelName}
          {finalPositionName}
          {finalTeamName}
          {finalTeamName && ' '}
          {location !== INTERVIEWS_ENDPOINT &&
            respectiveRoles[location]?.includes(role) &&
            headingName[location]}
        </h1>
        {finalPositionName && finalAllowedOperation && !isMobileScreen && (
          <FullScreenOverlay
            isForm
            isConfirmBeforeClose
            title={`${finalPositionName} - Upload Application`}
            RenderComponent={AddApplicationUIWrapper}
            buttonTitle="Application"
            positionData={{ id: positionData?.id, title: finalPositionName }}
          />
        )}
      </div>
      {(location === CALENDAR_ENDPOINT || location === TABULAR_EVENTS_ENDPOINT) && (
        <Button style={{ border: '1px solid rgba(82, 187, 171, 0.5)' }} onClick={handleSwitch}>
          {location === CALENDAR_ENDPOINT ? 'Tabular View' : 'Calendar View'}
        </Button>
      )}
      <div className="toolbar-item-container">
        {role !== ROLES.AGENCY && (
          <div className="search">
            <Search
              filterValues={search}
              setFilterValues={handleOnFilterChange}
              placeholder="Application (name, email, phone)"
            />
          </div>
        )}
        <Notifications />
        <LogoutButton />
      </div>
    </div>
  );
}

export default function ToolBarWrapper(props) {
  return (
    <ToolbarContainer {...props}>
      <ToolBarUI />
    </ToolbarContainer>
  );
}

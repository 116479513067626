import { isValidEmail, isValidMobileNumber } from '../../../utils';

import {
  EMAIL_ERROR,
  EMPTY_ERROR,
  GITHUB_LINK_ERROR,
  GITHUB_REGEX,
  LINKEDIN_LINK_ERROR,
  LINKEDIN_REGEX,
  PHONE_NUMBER_ERROR,
  SELECT_ERROR,
} from '../../../constants';

import { ACTIONS } from './constant';

const validatePosition = (position) => {
  if (position.title !== '') return { isValidPosition: true };
  return { isValidPosition: false, errorMsg: EMPTY_ERROR };
};

const validateName = (name) => {
  if (name !== '' && name.length >= 0) return { isValidName: true };
  return { isValidName: false, errorMsg: EMPTY_ERROR };
};

const validateCurrentLocation = (location) => {
  if (location !== '' && location.length >= 0) return { isValidCurrentLocation: true };
  return { isValidCurrentLocation: false, errorMsg: EMPTY_ERROR };
};

const validateEmail = (email) => {
  if (email.length === 0) return { isValidEmail: false, errorMsg: EMPTY_ERROR };
  if (!isValidEmail(email)) return { isValidEmail: false, errorMsg: EMAIL_ERROR };
  return { isValidEmail: true };
};

const validatePhone = (phoneNumber) => {
  if (!isValidMobileNumber(phoneNumber))
    return { isValidPhone: false, errorMsg: PHONE_NUMBER_ERROR };
  return { isValidPhone: true };
};

const validateNoticePeriod = (noticePeriod) => {
  if (noticePeriod !== '' && noticePeriod.length >= 0) return { isValidNoticePeriod: true };

  return { isValidNoticePeriod: false, errorMsg: EMPTY_ERROR };
};

const validateCurrentSalary = (salary) => {
  if (salary > 0) return { isValidCurrentSalary: true };
  return { isValidCurrentSalary: false, errorMsg: EMPTY_ERROR };
};

const validateExpectedSalary = (salary) => {
  if (salary > 0) return { isValidExpectedSalary: true };
  return { isValidExpectedSalary: false, errorMsg: EMPTY_ERROR };
};

const validateLink = (link, type) => {
  if (link === '') return { isValidLink: false, errorMsg: EMPTY_ERROR };
  if (!link.match(GITHUB_REGEX) && type === 'github')
    return { isValidLink: false, errorMsg: GITHUB_LINK_ERROR };
  if (!link.match(LINKEDIN_REGEX) && type === 'linkedIn')
    return { isValidLink: false, errorMsg: LINKEDIN_LINK_ERROR };
  return { isValidLink: true };
};

const validateLinkType = (linkType, type) => {
  if (linkType === 'none' && type === 'select')
    return { isValidLinkType: false, errorMsg: SELECT_ERROR };
  if (linkType === '' && type === 'field') return { isValidLinkType: false, errorMsg: EMPTY_ERROR };
  return { isValidLinkType: true };
};

const validateSkillLevel = (level) => {
  if (level === 'none' || level === '') return { isValidSkillLevel: false, errorMsg: SELECT_ERROR };
  return { isValidSkillLevel: true };
};

const validateSkill = (skill) => {
  if (skill.length === 0) return { isValidSkill: false, errorMsg: EMPTY_ERROR };
  return { isValidSkill: true };
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.VALIDATE_POSITION: {
      const position = action.payload.value;
      const { isValidPosition, errorMsg } = validatePosition(position);
      return {
        ...state,
        position,
        invalidFields: {
          ...state.invalidFields,
          position: !isValidPosition,
          positionErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.VALIDATE_AGENCY: {
      const agencyId = action.payload.value;
      return { ...state, agencyId };
    }
    case ACTIONS.VALIDATE_NAME: {
      const name = action.payload.value;
      const { isValidName, errorMsg } = validateName(name);
      return {
        ...state,
        name,
        invalidFields: {
          ...state.invalidFields,
          name: !isValidName,
          nameErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.VALIDATE_EMAIL: {
      const email = action.payload.value;
      const { isValidEmail: isEmailValid, errorMsg } = validateEmail(email);
      return {
        ...state,
        email,
        invalidFields: {
          ...state.invalidFields,
          email: !isEmailValid,
          emailErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.VALIDATE_PHONE: {
      const phone = action.payload.value.slice(0, 10);
      const { isValidPhone, errorMsg } = validatePhone(phone);
      return {
        ...state,
        phone,
        invalidFields: {
          ...state.invalidFields,
          phone: !isValidPhone,
          phoneErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.VALIDATE_LINK: {
      const link = action.payload.value;
      const { index } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));
      const { isValidLink, errorMsg } = validateLink(link, state.links[index].type);
      newState.links[index].value = link;
      newState.invalidFields.links[index].value.errorMsg = errorMsg;
      newState.invalidFields.links[index].value.valid = !isValidLink;
      return { ...newState };
    }
    case ACTIONS.VALIDATE_LINK_TYPE: {
      const linkType = action.payload.value;
      const { index, type } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));
      const { isValidLinkType, errorMsg } = validateLinkType(linkType, type);
      newState.links[index].type = linkType;
      if (type === 'select') {
        newState.invalidFields.links[index].type.errorMsg = errorMsg;
        newState.invalidFields.links[index].type.valid = !isValidLinkType;
      }
      if (type === 'field') {
        newState.invalidFields.links[index].typeField.errorMsg = errorMsg;
        newState.invalidFields.links[index].typeField.valid = !isValidLinkType;
      }
      return { ...newState };
    }

    case ACTIONS.VALIDATE_SKILL: {
      const skill = action.payload.value;
      const { index } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));
      const { isValidSkill, errorMsg } = validateSkill(skill);
      newState.skills[index].name = skill;
      newState.invalidFields.skills[index].name.errorMsg = errorMsg;
      newState.invalidFields.skills[index].name.valid = !isValidSkill;
      return { ...newState };
    }

    case ACTIONS.VALIDATE_SKILL_LEVEL: {
      const level = action.payload.value;
      const { index } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));
      const { isValidSkillLevel, errorMsg } = validateSkillLevel(level);
      newState.skills[index].level = level;
      newState.invalidFields.skills[index].level.errorMsg = errorMsg;
      newState.invalidFields.skills[index].level.valid = !isValidSkillLevel;
      return { ...newState };
    }

    case ACTIONS.NOTICE_PERIOD: {
      const noticePeriod = action.payload.value;
      const { isValidNoticePeriod, errorMsg } = validateNoticePeriod(noticePeriod);
      return {
        ...state,
        noticePeriod,
        invalidFields: {
          ...state.invalidFields,
          noticePeriod: !isValidNoticePeriod,
          noticePeriodErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.CURRENT_SALARY: {
      const currentSalary = action.payload.value;
      const { isValidCurrentSalary, errorMsg } = validateCurrentSalary(currentSalary);
      return {
        ...state,
        currentSalary,
        invalidFields: {
          ...state.invalidFields,
          currentSalary: !isValidCurrentSalary,
          currentSalaryErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.EXPECTED_SALARY: {
      const expectedSalary = action.payload.value;
      const { isValidExpectedSalary, errorMsg } = validateExpectedSalary(expectedSalary);
      return {
        ...state,
        expectedSalary,
        invalidFields: {
          ...state.invalidFields,
          expectedSalary: !isValidExpectedSalary,
          expectedSalaryErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.ADDITIONAL_INFO: {
      const additionalInfo = action.payload.value;
      return { ...state, additionalInfo };
    }
    case ACTIONS.CURRENT_LOCATION: {
      const currentLocation = action.payload.value;
      const { isValidCurrentLocation, errorMsg } = validateCurrentLocation(currentLocation);
      return {
        ...state,
        currentLocation,
        invalidFields: {
          ...state.invalidFields,
          currentLocation: !isValidCurrentLocation,
          currentLocationErrorMsg: errorMsg,
        },
      };
    }
    case ACTIONS.RECAPTCHA: {
      const recaptcha = action.payload.value;
      return { ...state, recaptcha };
    }
    case ACTIONS.RESUME: {
      const resume = action.payload.value;
      return { ...state, resume };
    }
    case ACTIONS.IS_UPLOAD: {
      const isUpload = action.payload.value;
      return { ...state, isUpload };
    }
    case ACTIONS.CLEAR_RESUME: {
      return { ...state, resume: { name: '', path: '' } };
    }
    case ACTIONS.ADD_SECTION: {
      const section = action.payload.value;
      const newState = JSON.parse(JSON.stringify(state));
      switch (section) {
        case 'link': {
          newState.links.push({ value: '', type: '' });
          newState.invalidFields.links.push({
            type: { valid: true, errorMsg: '' },
            typeField: { valid: true, errorMsg: '' },
            value: { valid: true, errorMsg: '' },
          });
          break;
        }
        case 'skill': {
          newState.skills.push({ level: '', name: '' });
          newState.invalidFields.skills.push({
            name: { valid: true, errorMsg: '' },
            level: { valid: true, errorMsg: '' },
          });
          break;
        }
        default:
          return { ...state };
      }
      return newState;
    }

    case ACTIONS.REMOVE_SECTION: {
      const section = action.payload.value;
      const { index } = action.payload;
      const newState = JSON.parse(JSON.stringify(state));
      switch (section) {
        case 'link': {
          newState.links.splice(index, 1);
          newState.invalidFields.links.splice(index, 1);
          break;
        }
        case 'skill': {
          newState.skills.splice(index, 1);
          newState.invalidFields.skills.splice(index, 1);
          break;
        }
        default:
          return { ...state };
      }
      return newState;
    }
    default:
      return { ...state };
  }
};

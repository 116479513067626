import { useQuery } from '@tanstack/react-query';

import { createDepartments } from '../../Repositories';

const departmentReq = createDepartments();

/**
 * this function(query) is to get All department
 * @function useDepartmentGetQuery
 * @param {Object} reqParams params pass along with the api call
 * @param {Object} options to be passed as query handling
 * @returns api response
 */
export function useDepartmentGetQuery(reqParams, options) {
  return useQuery(
    ['departmentGetQuery', reqParams],
    () => departmentReq.getAllDepartment(reqParams),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      ...options,
    }
  );
}

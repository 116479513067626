// required module
import React, { useEffect, useReducer, useState } from 'react';

import { AddApplicationContainer } from '../../../Container';

// utility function
import { filter4xxAnd503Error, filter4xxError } from '../../../utils';
// utility function
import { formateObject } from './utils';

import { LENGTH_ERROR, MAX_CHARACTER_LIMIT } from '../../../constants';

import { Error, PopupError } from '../../Error';
// required Components
import CompleteLoader from '../../Loader/CompletePageLoader';
import ApplicationForm from '../Form';

// constants
import { ACTIONS, INITIAL_FORM_FIELD_STATE } from './constant';
// reducer
import { reducer } from './reducer';

export function AddApplication({
  positionData,
  postToApplications,
  getCaptcha,
  handleSubmit,
  handleDuplicateCheck,
  postDuplicateCheck,
}) {
  // initial states
  const [errors, setErrors] = useState({});
  const [state, dispatch] = useReducer(reducer, INITIAL_FORM_FIELD_STATE);
  const [markedHtmlContent, setMarkedHtmlContent] = useState('');
  const [reupload, setReupload] = useState(false);
  /**
   * this function is setting value of application form and making validations
   * @param {*} event
   * @param {*} i
   */
  const handleApplicationFormFields = (event, i = undefined) => {
    const { name, value, checked } = event.target;
    dispatch(formateObject(name, value, i, checked));
  };

  /**
   * this function is setting value of application form on out of focus and making validations
   * @param {*} event
   * @param {*} i
   */
  const handleApplicationFormFieldsBlur = (event, i = undefined) => {
    const { name, value, checked } = event.target;
    dispatch(formateObject(name, value.trim(), i, checked));
  };

  /**
   * this function is taken care of add and remove section in skills and link fields.
   * @function handleAddAndRemoveSection
   * @param {*} section
   * @param {*} index optional
   */
  const handleAddAndRemoveSection = (section, index = -1) => {
    if (index !== -1) {
      dispatch({ type: ACTIONS.REMOVE_SECTION, payload: { value: section, index } });
    } else {
      dispatch({ type: ACTIONS.ADD_SECTION, payload: { value: section } });
    }
  };

  /**
   * this function is taken care of handling additional Info , validate and setting error according
   * @param {*} item current state of editor
   */
  const handleQuillAdditionalInfo = (item) => {
    setMarkedHtmlContent(item);
    setErrors({
      ...errors,
      additionalInfoError:
        item?.replace(/(<([^>]+)>)|\n|&nbsp;/gi, '').length > MAX_CHARACTER_LIMIT
          ? LENGTH_ERROR
          : '',
    });
    dispatch({ type: ACTIONS.ADDITIONAL_INFO, payload: { value: item } });
  };

  const handleOnFileLoadSuccess = (resumeLink) => {
    dispatch({ type: ACTIONS.RESUME, payload: { value: resumeLink.data.value } });
  };

  /**
   * handler function for submit application
   */
  const handleSubmitForAddApplication = (e, noticeValue) => {
    e.preventDefault();
    handleSubmit(state, noticeValue);
  };
  useEffect(() => {
    if (reupload) {
      dispatch({ type: ACTIONS.IS_UPLOAD, payload: { value: reupload } });
    }
  }, [reupload]);
  /**
   * handler function for duplicate application check
   */
  const { email, phone, position, links } = state;
  useEffect(() => {
    if (position !== '' && phone !== '' && phone.length === 10 && email !== '' && !links.length) {
      const reqBody = {
        position,
        phone,
        email,
      };
      handleDuplicateCheck(reqBody);
    }
  }, [email, phone, position]);
  // render ui component
  return (
    <>
      {postToApplications.isLoading && <CompleteLoader />}
      {postToApplications.isError && (
        <PopupError
          errors={[postToApplications.error]}
          errorMapper={filter4xxAnd503Error}
          setReupload={setReupload}
        />
      )}
      {postDuplicateCheck.isError && (
        <PopupError
          errors={[postDuplicateCheck.error]}
          errorMapper={filter4xxAnd503Error}
          setReupload={setReupload}
        />
      )}
      {getCaptcha.isError ? (
        <Error errors={[getCaptcha.error]} errorMapper={filter4xxError} />
      ) : (
        <ApplicationForm
          state={state}
          positionData={positionData}
          errors={errors}
          markedHtmlContent={markedHtmlContent}
          handleQuillAdditionalInfo={handleQuillAdditionalInfo}
          handleApplicationFormFields={handleApplicationFormFields}
          handleApplicationFormFieldsBlur={handleApplicationFormFieldsBlur}
          handleAddAndRemoveSection={handleAddAndRemoveSection}
          handleSubmit={handleSubmitForAddApplication}
          handleOnFileLoadSuccess={handleOnFileLoadSuccess}
          token={getCaptcha}
          isLoading={postToApplications.isLoading}
        />
      )}
    </>
  );
}

export default function AddApplicationUIWrapper(props) {
  return (
    <AddApplicationContainer {...props}>
      <AddApplication />
    </AddApplicationContainer>
  );
}

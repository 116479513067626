import { createFetcher } from '../../Common';

import { queryBuilder } from '../../utils';

import { BASE_URL, DEPARTMENTS_ENDPOINT } from '../../constants';

/**
 * repository for department
 */
export class Departments {
  static getInstance() {
    if (!this.instance) {
      this.instance = new Departments(createFetcher());
    }
    return this.instance;
  }

  constructor(fetcher) {
    this.fetcher = fetcher;
    this.base_url = BASE_URL;
    this.endpoint = DEPARTMENTS_ENDPOINT;
  }

  /**
   * to get all department
   * @param {Object} reqParam
   * @returns response in required formate of all department
   */

  async getAllDepartment(reqParam) {
    const queryString = queryBuilder(reqParam);
    const url = `${this.base_url}${this.endpoint}${queryString}`;
    const response = await this.fetcher.getData(url);
    return { data: response?.data, meta: response?.meta };
  }
}

export const createDepartments = () => Departments.getInstance();

import { Children, cloneElement, useEffect } from 'react';

import { useAddLocationQuery } from '../../../Common/Queries/locationQueries';

/**
 * @function formateData
 * @param value
 * @returns formated values
 */
export const formateData = (value) => {
  return { name: value.name };
};
const initialValues = { name: '' };

export function AddLocationContainer({ children, setOpen }) {
  const child = Children.only(children);
  const postToLocation = useAddLocationQuery();

  /**
   * this function is utility function for submit data get by user
   * @param e default parameter
   * @param values location parameter
   */
  function handleSubmitForAddLocation(values) {
    const reqBody = formateData(values);
    postToLocation.mutate(reqBody);
  }

  useEffect(() => {
    if (postToLocation.isSuccess) setOpen(false);
  }, [postToLocation.isSuccess]);

  return cloneElement(child, { postToLocation, handleSubmitForAddLocation, initialValues });
}

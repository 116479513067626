import { Children, cloneElement } from 'react';

import { useApplicationsGetQuery } from '../../Common/Queries/applicationsQueries';

import { ALL_DATA_LIMIT } from '../../constants';

export const ScreeningListContainer = ({ children }) => {
  const screeningApplication = useApplicationsGetQuery({
    view: 'RESUME_SCREENING',
    limit: ALL_DATA_LIMIT,
  });
  const child = Children.only(children);

  return cloneElement(child, { screeningApplication });
};

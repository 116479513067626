// @ts-check

/**
 * @typedef {Object} InitialStateApplyFormFields
 * @property {String} position
 * @property {Number} agencyId
 * @property {String} name
 * @property {String} phone
 * @property {String} email
 * @property {Array} links
 * @property {Array} skills
 * @property {String} resume
 * @property {String} additionalInfo
 * @property {String} noticePeriod
 * @property {Number} currentSalary
 * @property {Number} expectedSalary
 * @property {String} currentLocation
 * @property {string} recaptcha
 * @property {Boolean} isUpload
 * @property {Object} invalidFields
 */

/**
 * @define initial state for form fields of apply to a position
 * @type {InitialStateApplyFormFields} INITIAL_FORM_FIELD_STATE
 */

export const INITIAL_FORM_FIELD_STATE = {
  position: '',
  agencyId: 0,
  name: '',
  email: '',
  phone: '',
  links: [],
  skills: [],
  resume: '',
  additionalInfo: '',
  noticePeriod: '',
  expectedSalary: 0,
  currentSalary: 0,
  currentLocation: '',
  recaptcha: '',
  isUpload: false,
  invalidFields: {
    position: false,
    name: false,
    nameErrorMsg: '',
    email: false,
    emailErrorMsg: '',
    phone: false,
    phoneErrorMsg: '',
    noticePeriod: false,
    noticePeriodErrorMsg: '',
    currentSalary: false,
    currentSalaryErrorMsg: '',
    expectedSalary: false,
    expectedSalaryErrorMsg: '',
    links: [],
    skills: [],
  },
};

/**
 *@define Actions for validating, setting values, add and remove specific section of application form
 */
export const ACTIONS = {
  VALIDATE_POSITION: 'VALIDATE_POSITION',
  VALIDATE_AGENCY: 'VALIDATE_AGENCY',
  VALIDATE_NAME: 'VALIDATE_NAME',
  VALIDATE_EMAIL: 'VALIDATE_EMAIL',
  VALIDATE_PHONE: 'VALIDATE_PHONE',
  VALIDATE_LINK: 'VALIDATE_LINK',
  VALIDATE_LINK_TYPE: 'VALIDATE_LINK_TYPE',
  VALIDATE_SKILL: 'VALIDATE_SKILL',
  VALIDATE_SKILL_LEVEL: 'VALIDATE_SKILL_LEVEL',
  NOTICE_PERIOD: 'NOTICE_PERIOD',
  CURRENT_SALARY: 'CURRENT_SALARY',
  EXPECTED_SALARY: 'EXPECTED_SALARY',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO',
  CURRENT_LOCATION: 'CURRENT_LOCATION',
  RESUME: 'RESUME',
  RECAPTCHA: 'RECAPTCHA',
  ADD_SECTION: 'ADD_SECTION',
  REMOVE_SECTION: 'REMOVE_SECTION',
  CLEAR_RESUME: 'CLEAR_RESUME',
  IS_UPLOAD: 'IS_UPLOAD',
};

/**
 *@define initial touch value use for application form validation
 */
export const INITIAL_TOUCH = { linksTouch: false, skillsTouch: false };

import React, { useContext } from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';

import { getCurrentDate } from '../../../../utils';

import { AppContext, EMAIL_REGEX, NOTICE_PERIOD_OPTIONS } from '../../../../constants';

import { FileUpload } from '../../../FileUpload';
import { InputNumber, InputText } from '../../../Input';
import SearchWithGroupItem from '../../../SearchBox/searchWithGroupItem';
import { LabelName, Required } from '../../../utility';
import { CustomSelect } from '../../Select';

export default function BasicDetails({
  positionData,
  state,
  handleFormFields,
  handleFormFieldsBlur,
  isCustomNotice,
  tempNotice,
  handleNoticePeriod,
  handleOnFileLoadSuccess,
  token,
}) {
  const { appData } = useContext(AppContext);

  const AGENCY_OPTIONS = appData.AGENCY_DATA || [];
  const POSITION_OPTIONS = appData?.POSITION_DATA || [];

  return (
    <>
      {!positionData && (
        <Grid item xs={12}>
          <LabelName>
            Position
            <Required> *</Required>
          </LabelName>
          <SearchWithGroupItem
            options={POSITION_OPTIONS}
            property="title"
            isObject
            initialState={state.position || {}}
            setState={(positionValue) =>
              handleFormFields({
                target: { name: 'position', value: positionValue },
              })
            }
            suggestionText={POSITION_OPTIONS.length !== 0 ? 'Enter position title' : 'No Options'}
            placeholder="Search position"
            required
            helperText={state.invalidFields.positionErrorMsg}
            error={state.invalidFields.position}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <LabelName>
          Name<Required> *</Required>
        </LabelName>
        <InputText
          placeholder="Enter Name"
          name="name"
          id="name"
          required
          inputProps={{ 'data-testid': 'testName', maxLength: 100 }}
          error={state.invalidFields.name}
          helperText={state.invalidFields.nameErrorMsg}
          value={state.name}
          onChange={handleFormFields}
          onBlur={handleFormFieldsBlur}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <LabelName>
          Email<Required> *</Required>
        </LabelName>
        <InputText
          placeholder="Enter Email"
          name="email"
          id="email"
          inputProps={{
            'data-testid': 'testEmail',
            maxLength: 100,
            pattern: (state.invalidFields.email && EMAIL_REGEX) || null,
          }}
          required
          error={state.invalidFields.email}
          helperText={state.invalidFields.emailErrorMsg}
          value={state.email}
          onChange={handleFormFields}
          onBlur={handleFormFields}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <LabelName>
          Phone Number
          <Required> *</Required>
        </LabelName>
        <InputNumber
          value={state.phone}
          handleChange={handleFormFields}
          isRequired
          isError={state.invalidFields.phone}
          errorMessage={state.invalidFields.phoneErrorMsg}
          placeholder="Enter Phone Number"
          name="phone-number"
          inputProps={{
            'data-testid': 'testPhoneNumber',
          }}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <LabelName>
          Current Salary (In LPA)
          <Required> *</Required>
        </LabelName>
        <InputNumber
          isRequired
          placeholder="Enter current salary"
          name="current-salary"
          value={state.currentSalary || ''}
          handleChange={handleFormFields}
          inputProps={{
            'data-testid': 'testCurrentSalary',
            min: 0,
          }}
          isError={state.invalidFields.currentSalary}
          errorMessage={state.invalidFields.currentSalaryErrorMsg}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <LabelName>
          Expected Salary (In LPA)
          <Required> *</Required>
        </LabelName>
        <InputNumber
          isRequired
          placeholder="Enter expected salary"
          name="expected-salary"
          value={state.expectedSalary || ''}
          handleChange={handleFormFields}
          inputProps={{
            'data-testid': 'testExpectedSalary',
            min: 0,
          }}
          isError={state.invalidFields.expectedSalary}
          errorMessage={state.invalidFields.expectedSalaryErrorMsg}
        />
      </Grid>

      <Grid item xs={12} md={isCustomNotice ? 3 : 6}>
        <CustomSelect
          {...{
            labelText: 'Notice Period',
            isRequired: true,
            options: NOTICE_PERIOD_OPTIONS,
            defaultValue: tempNotice,
            getTitle: (value) => value,
            labelFor: 'notice-period',
            fieldName: 'notice period',
            onChangeHandler: handleNoticePeriod,
          }}
        />
      </Grid>

      {isCustomNotice && (
        <Grid item xs={12} md={3}>
          <LabelName>
            Select date
            <Required> *</Required>
          </LabelName>
          <TextField
            name="notice-period"
            type="date"
            placeholder="Enter end date"
            variant="outlined"
            fullWidth
            sx={{ marginTop: '5px' }}
            id="position-end-date"
            inputProps={{
              'data-testid': 'position-end-date',
              min: getCurrentDate(),
            }}
            required
            value={state.noticePeriod.slice(0, 10) || ''}
            onChange={handleFormFields}
            error={state.invalidFields.noticePeriod}
            helperText={state.invalidFields.noticePeriodErrorMsg}
          />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <span className="inputName-applicant">
          Upload Resume
          <Required> *</Required>
        </span>
        <FileUpload
          handleOnSuccess={handleOnFileLoadSuccess}
          token={token}
          buttonText="Upload Resume"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <LabelName>
          Current Location <Required> *</Required>
        </LabelName>
        <InputText
          placeholder="Candidate's current location"
          name="current-location"
          value={state.currentLocation}
          id="current-location"
          required
          inputProps={{ 'data-testid': 'testCurrentLocation', maxLength: 200 }}
          onChange={handleFormFields}
          onBlur={handleFormFieldsBlur}
          error={state.invalidFields.currentLocation}
          helperText={state.invalidFields.currentLocationErrorMsg}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <LabelName>Agency</LabelName>
        <TextField
          size="small"
          name="agency"
          id="agency"
          fullWidth
          sx={{ marginTop: '5px' }}
          variant="outlined"
          inputProps={{ 'data-testid': 'testAgency' }}
          value={state.agencyId}
          select
          onChange={handleFormFields}
        >
          <MenuItem value={0}>Select</MenuItem>
          {AGENCY_OPTIONS.map((option) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            );
          })}
        </TextField>
      </Grid>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

import './index.css';

const Span = styled('span')({ paddingLeft: '1rem' });

export function CustomSelect({
  options = [],
  defaultValue,
  labelFor,
  onChangeHandler,
  isRequired,
  fieldName,
  labelText,
  className,
  isLoading = false,
  getTitle = (value) => value.title,
}) {
  const [value, setValue] = useState(defaultValue);

  const handleOnChange = (e) => {
    setValue(e.target.value);
    if (typeof onChangeHandler === 'function') {
      onChangeHandler(e.target.value);
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  // If there is only one option for select then directly select it (for mandatory input fields)
  useEffect(() => {
    if (isRequired && options.length === 1) {
      setValue(options[0].id || options[0]);
      onChangeHandler(options[0].id || options[0]);
    }
  }, [options]);

  return (
    <>
      <span className="label">
        {labelText}
        {isRequired && labelFor && <em className="requiredInput"> *</em>}
      </span>
      <Select
        value={value || ''}
        required={!!isRequired}
        size="small"
        id={labelFor}
        className={className ?? 'inputText'}
        defaultValue={defaultValue}
        variant="outlined"
        inputProps={{ 'data-testid': 'testSelect' }}
        sx={{
          '&:hover': {
            '&& fieldset': {
              border: '1px solid #F3F7F7',
            },
          },
        }}
        onChange={handleOnChange}
        MenuProps={{ PaperProps: { sx: { maxHeight: '30rem' } } }}
      >
        {isLoading && <Span>Loading...</Span>}
        {!isLoading && <MenuItem value="" disabled={isRequired}>{`Select ${fieldName}`}</MenuItem>}
        {!isLoading &&
          (options || []).map((option) => (
            <MenuItem key={option.id || option} value={option.id || option}>
              {getTitle(option)}
            </MenuItem>
          ))}
      </Select>
    </>
  );
}

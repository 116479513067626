import { Children, cloneElement, useEffect } from 'react';

import { useMasterInviteTemplateGetQuery } from '../../Common/Queries/masterInviteTemplateQueries';
import { usePositionsPostQuery } from '../../Common/Queries/positionsQueries';

import { htmlToMD } from '../../utils';

/**
 * @function formateData
 * @param values
 * @returns formated values
 */
export const formateData = (values) => {
  return {
    description: htmlToMD(values.description),
    location: values.location,
    title: values.title,
    interviewer: values.interviewer,
    hr: values.hr,
    isCampus: values.isCampus,
    isPublish: values.isPublish,
    skill: values.skill,
    stream: values.stream,
    level: values.level,
    offlineInviteTemplate: values.offlineInviteTemplate,
    onlineInviteTemplate: values.onlineInviteTemplate,
    endDate: values.endDate,
    skipRounds: values.skipRounds,
  };
};

export function AddPositionContainer({ setOpen, children, ...props }) {
  const postToPositions = usePositionsPostQuery();
  const getMasterInviteTemplates = useMasterInviteTemplateGetQuery();

  function handleSubmitForAddPosition(values) {
    const reqBody = formateData(values);
    postToPositions.mutate(reqBody);
  }

  useEffect(() => {
    if (postToPositions.isSuccess) {
      setOpen(false);
    }
  }, [postToPositions.isSuccess]);

  return Children.only(
    cloneElement(children, {
      postToPositions,
      getMasterInviteTemplates,
      handleSubmitForAddPosition,
      ...props,
    })
  );
}

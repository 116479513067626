import React, { forwardRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Snackbar,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import {
  ERROR_MESSAGE,
  ErrorTypes,
  LOGGED_IN_USER_INFO,
  LOGIN_ENDPOINT,
  UPSTREAM_INVALID_TOKEN_MESSAGE,
} from '../../constants';

import { getParsedValueFromLocalStorage } from '../../services/localStorage';

import './error.css';

/**
 * this function is react functional component which render error page
 * @returns error message
 */
function SimpleError({ message }) {
  return (
    <div className="error-container" data-testid="simple-error">
      <span className="error">{message || ERROR_MESSAGE}</span>
    </div>
  );
}

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TransitionRight = forwardRef(function TransitionRight(props, ref) {
  return <Slide {...props} direction="right" ref={ref} />;
});

function AlertErrorComponent({ message, handleClose }) {
  const errorMessage = message;
  const [snackOpen, setSnackOpen] = useState(true);

  /**
   * for closing alert message
   * @function handleSnackClose
   */
  const handleSnackClose = () => {
    setSnackOpen(false);
    if (handleClose) handleClose(false);
  };

  useEffect(() => {
    setSnackOpen(true);
  }, [errorMessage]);

  // render ui component
  return (
    <Snackbar
      open={snackOpen}
      autoHideDuration={5000}
      onClose={handleSnackClose}
      TransitionComponent={TransitionRight}
      sx={{ maxWidth: 450 }}
    >
      <Alert onClose={handleSnackClose} severity="error" data-testid="alert-error">
        <Typography className="alert-error-message">{errorMessage || ERROR_MESSAGE}</Typography>
      </Alert>
    </Snackbar>
  );
}

const TransitionDown = React.forwardRef(function TransitionDown(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function PopupErrorComponent({ message, handleClose, details, setReupload, status }) {
  const [open, setOpen] = useState(true);
  const [newMessage, setNewMessage] = useState(message);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const handlePopupClose = () => {
    setOpen(false);
    if (handleClose) handleClose(false);
  };
  useEffect(() => {
    if (status === 409) {
      setNewMessage((prevMessage) => prevMessage.replace(/\[\d+(?:\s+\d+)*\]/g, ''));
      if (newMessage.includes('upload')) {
        setShowUploadButton(true);
      }
    }
  }, []);

  const handleUploadButton = () => {
    setOpen(false);
    setReupload(true);
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionDown}
      keepMounted
      onClose={handlePopupClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle data-testid="popup-error">Error</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {newMessage || ERROR_MESSAGE}
          {status === 409 ? (
            <>
              {details?.map((item) => (
                <a
                  href={`/applications/${item}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={item}
                  className="appid-link"
                >
                  {item}{' '}
                </a>
              ))}
            </>
          ) : null}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {showUploadButton && (
          <Button onClick={handleUploadButton} variant="contained">
            Upload
          </Button>
        )}
        <Button onClick={handlePopupClose} variant="outlined">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function CustomPopupErrorComponent({ CustomComponent, handleClose = () => {} }) {
  const [open, setOpen] = useState(true);
  const handlePopupClose = (handleCloseFlag) => {
    setOpen(false);
    if (handleClose && handleCloseFlag) handleClose(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={TransitionDown}
      keepMounted
      onClose={() => {
        handlePopupClose(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle data-testid="custom-popup-error">Error</DialogTitle>
      <DialogContent>
        <CustomComponent />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            handlePopupClose(true);
          }}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ErrorWrapper({
  message,
  errorMapper,
  errors,
  handleClose = () => {},
  CustomComponent,
  type,
  setReupload,
}) {
  const navigate = useNavigate();
  let errorMessage = message;
  const errorComponentType = type;
  const error = typeof errorMapper === 'function' ? errorMapper(errors) : undefined;
  useEffect(() => {
    if (!getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO)) navigate(`${LOGIN_ENDPOINT}`);
  }, [getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO)]);

  if (error) {
    errorMessage = error.message;
  }

  if (errorMessage === 'undefined' || errorMessage === 'false') {
    errorMessage = '';
  }

  return (
    <>
      {error?.message !== UPSTREAM_INVALID_TOKEN_MESSAGE && (
        <>
          {errorComponentType === ErrorTypes.simple && <SimpleError message={errorMessage} />}
          {errorComponentType === ErrorTypes.popOver && (
            <PopupErrorComponent
              message={errorMessage}
              handleClose={handleClose}
              details={error?.details}
              setReupload={setReupload}
              status={error?.status}
            />
          )}
          {errorComponentType === ErrorTypes.alert && (
            <AlertErrorComponent message={errorMessage} handleClose={handleClose} />
          )}
          {errorComponentType === ErrorTypes.custom && (
            <CustomPopupErrorComponent
              message={errorMessage}
              CustomComponent={CustomComponent}
              handleClose={handleClose}
            />
          )}
        </>
      )}
    </>
  );
}

function ErrorFactory(type) {
  return function Error(props) {
    return <ErrorWrapper type={type} {...props} />;
  };
}

export const Error = ErrorFactory(ErrorTypes.simple);
export const PopupError = ErrorFactory(ErrorTypes.popOver);
export const AlertError = ErrorFactory(ErrorTypes.alert);
export const CustomError = ErrorFactory(ErrorTypes.custom);

// required module
// css file
import React, { useRef, useState } from 'react';
import { FormControl, FormHelperText, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { GITHUB_REGEX, LINKEDIN_REGEX } from '../../../constants';

// required Components
import { PrimaryCustomButton } from '../../CustomButton';
import QuillEditor from '../../Editor';
import BasicDetails from '../../Form/BasicDetails/Application';
import Links from '../../Form/LinksApplication';
import Skills from '../../Form/Skills';
import { LabelName } from '../../utility';

import './form.css';

/**
 * @define initial touch value use for application form validation
 */
const INITIAL_TOUCH = { linksTouch: false, skillsTouch: false };
const requiredStates = [
  'currentLocation',
  'currentSalary',
  'email',
  'expectedSalary',
  'name',
  'noticePeriod',
  'phone',
  'position',
];

// for styling component

const ContainerDiv = styled('div')({ marginTop: '10px' });
const GridDiv = styled('div')({ flexGrow: 1 });

/**
 * this function is react functional component which render application form
 * @param {*} props
 * @returns functional component
 */
export default function ApplicationForm({
  positionData,
  state,
  handleSubmit,
  handleApplicationFormFields,
  handleApplicationFormFieldsBlur,
  handleAddAndRemoveSection,
  markedHtmlContent,
  handleQuillAdditionalInfo,
  errors,
  handleOnFileLoadSuccess,
  token,
  isLoading,
}) {
  const formRef = useRef();

  // initial state
  const [isTouch, setIsTouch] = useState(INITIAL_TOUCH);
  const [expanded, setExpanded] = useState(false);
  const [tempNotice, setTempNotice] = useState('');
  const isCustomNotice = tempNotice === 'Immediate' || tempNotice === 'Serving';

  const validationCheckForInvalidFields = (key) =>
    !state.invalidFields[key] && !state.invalidFields[`${key}ErrorMsg`];

  const handleLinksValidation = (links) => {
    if (
      links.every(
        (item) =>
          (item.value.match(GITHUB_REGEX) && item.type === 'github') ||
          (item.value.match(LINKEDIN_REGEX) && item.type === 'linkedIn') ||
          (item.type !== 'none' &&
            item.type !== 'github' &&
            item.type !== 'linkedIn' &&
            item.type &&
            item.value)
      )
    )
      setIsTouch({ ...isTouch, linksTouch: false });
    else setIsTouch({ ...isTouch, linksTouch: true });
  };

  const handleSkillsValidation = (skills) => {
    if (skills.every((item) => item.name && item.level !== 'none'))
      setIsTouch({ ...isTouch, skillsTouch: false });
    else setIsTouch({ ...isTouch, skillsTouch: true });
  };

  const handleNoticePeriod = (updatedNoticePeriod) => {
    setTempNotice(updatedNoticePeriod);
    if (updatedNoticePeriod !== 'Immediate' && updatedNoticePeriod !== 'Serving')
      handleApplicationFormFields({
        target: { value: updatedNoticePeriod, name: 'notice-period' },
      });
    else handleApplicationFormFields({ target: { value: '', name: 'notice-period' } });
  };

  // render ui component
  return (
    <div className={`"complete-form-applicant" ${isLoading ? 'applicantFormDisabled' : ''}`}>
      <div className="page-applicant">
        <div className="applicant-container-box">
          <form onSubmit={(e) => handleSubmit(e, tempNotice)} className="form" ref={formRef}>
            <div className="applicant-info">
              <GridDiv>
                <Grid container spacing={2}>
                  <BasicDetails
                    positionData={positionData}
                    state={state}
                    handleFormFields={handleApplicationFormFields}
                    handleFormFieldsBlur={handleApplicationFormFieldsBlur}
                    isCustomNotice={isCustomNotice}
                    tempNotice={tempNotice}
                    handleNoticePeriod={handleNoticePeriod}
                    handleOnFileLoadSuccess={handleOnFileLoadSuccess}
                    token={token}
                  />
                </Grid>
              </GridDiv>
              <div className="applicant-same-row-border">
                <LabelName>Links, Skills</LabelName>
                <div className="add-remove-button-container_application_form">
                  <span
                    data-testid="testAddLinkButton"
                    className="add-another-section-text_application_form"
                    id="link-add-button"
                    role="button"
                    onClick={() => setExpanded((prev) => !prev)}
                    tabIndex={0}
                    onKeyDown={() => {}}
                  >
                    {expanded ? 'Hide' : 'Expand'}
                  </span>
                </div>
                {expanded && (
                  <>
                    <Links
                      state={state}
                      isTouch={isTouch}
                      handleLinksValidation={handleLinksValidation}
                      handleFormFields={handleApplicationFormFields}
                      handleAddAndRemoveSection={handleAddAndRemoveSection}
                    />

                    <Skills
                      state={state}
                      handleSkillsValidation={handleSkillsValidation}
                      isTouch={isTouch}
                      handleFormFields={handleApplicationFormFields}
                      handleAddAndRemoveSection={handleAddAndRemoveSection}
                    />
                  </>
                )}
              </div>
              <ContainerDiv>
                <GridDiv>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className="application-same-row">
                        <LabelName>Additional Information</LabelName>
                        <FormControl
                          sx={{ marginTop: '5px' }}
                          error={errors.additionalInfoError !== ''}
                        >
                          <QuillEditor
                            wrapperId="additional-info"
                            placeholder="Enter Additional Information"
                            description={markedHtmlContent}
                            onChangeSelection={handleQuillAdditionalInfo}
                          />
                          <FormHelperText className="helper-text__position_form">
                            {errors.additionalInfoError}
                          </FormHelperText>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
                </GridDiv>
              </ContainerDiv>
            </div>
            <div className="button-applicant">
              <PrimaryCustomButton
                data-testid="testSave"
                id="submit-button"
                type="submit"
                disabled={
                  state?.resume?.length === 0 ||
                  errors.additionalInfoError ||
                  isLoading ||
                  !requiredStates.every(validationCheckForInvalidFields)
                }
              >
                Save
              </PrimaryCustomButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

import { Children, cloneElement, useContext } from 'react';

import { useAgenciesGetQuery } from '../../Common/Queries/agenciesQueries';
import { useDepartmentGetQuery } from '../../Common/Queries/departmentQueries';
import { useFiltersGetQuery } from '../../Common/Queries/filterQueries';
import { useLevelsGetQuery } from '../../Common/Queries/levelQueries';
import { usePositionsGetQuery } from '../../Common/Queries/positionsQueries';
import { useSkillsGetQuery } from '../../Common/Queries/skillQueries';
import { useStreamGetQuery } from '../../Common/Queries/streamQueries';
import { useUsersGetQuery } from '../../Common/Queries/usersQueries';

import { isLogin } from '../../utils';

import { ALL_DATA_LIMIT, AuthContext, DURATION_TIME, ROLES } from '../../constants';

export function SideBarContainer({ children, ...props }) {
  const { userInfo } = useContext(AuthContext);
  const role = userInfo?.roles && userInfo?.roles[0];

  const positionList = usePositionsGetQuery(
    { limit: ALL_DATA_LIMIT },
    { enabled: isLogin(), refetchInterval: DURATION_TIME, refetchIntervalInBackground: true }
  );
  const statusList = useFiltersGetQuery(
    { entity: 'application', field: 'status' },
    { enabled: isLogin(), refetchInterval: DURATION_TIME, refetchIntervalInBackground: true }
  );
  const departmentList = useDepartmentGetQuery(
    { limit: ALL_DATA_LIMIT },
    {
      enabled: isLogin() && role !== ROLES.AGENCY,
      refetchInterval: DURATION_TIME,
      refetchIntervalInBackground: true,
    }
  );
  const streamList = useStreamGetQuery(
    { limit: ALL_DATA_LIMIT },
    { enabled: isLogin(), refetchInterval: DURATION_TIME, refetchIntervalInBackground: true }
  );
  const skillList = useSkillsGetQuery(
    { limit: ALL_DATA_LIMIT },
    { enabled: isLogin(), refetchInterval: DURATION_TIME, refetchIntervalInBackground: true }
  );
  const levelList = useLevelsGetQuery(
    { limit: ALL_DATA_LIMIT },
    { enabled: isLogin(), refetchInterval: DURATION_TIME, refetchIntervalInBackground: true }
  );
  const userList = useUsersGetQuery(
    { limit: ALL_DATA_LIMIT, role: [ROLES.ADMIN, ROLES.HIRING_MANAGER, ROLES.HR].join(',') },
    {
      enabled: isLogin() && role !== ROLES.AGENCY,
      refetchInterval: DURATION_TIME,
      refetchIntervalInBackground: true,
    }
  );
  const agencyList = useAgenciesGetQuery(
    { limit: ALL_DATA_LIMIT },
    {
      enabled: isLogin() && role !== ROLES.AGENCY,
      refetchInterval: DURATION_TIME,
      refetchIntervalInBackground: true,
    }
  );

  return Children.only(
    cloneElement(children, {
      positionList,
      statusList,
      departmentList,
      streamList,
      skillList,
      levelList,
      userList,
      agencyList,
      ...props,
    })
  );
}
